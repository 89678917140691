/******************************************/
/* IMPORTANT: PLEASE USE ONLY WOFF2 FONTS */
/******************************************/

/***********/
/* Arsenal */
/***********/
@font-face {
  font-family: 'Arsenal';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Arsenal-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Arsenal';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Arsenal-Regular-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Arsenal';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Arsenal-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Arsenal';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Arsenal-Bold-Italic.woff2') format('woff2');
}

/*******************/
/* Source Sans Pro */
/*******************/
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/SourceSansPro-Light.woff2') format('woff2');
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2');
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/SourceSansPro-Semibold.woff2') format('woff2');
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/SourceSansPro-Bold.woff2') format('woff2');
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/SourceSansPro-Regular-Italic.woff2') format('woff2');
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*****************************/
/* Source Sans Pro latin-ext */
/*****************************/
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/SourceSansPro-Latin-ext-Regular.woff2') format('woff2');
  unicode-range:
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/SourceSansPro-Latin-ext-Light.woff2') format('woff2');
  unicode-range:
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/SourceSansPro-Latin-ext-Semibold.woff2') format('woff2');
  unicode-range:
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/SourceSansPro-Latin-ext-Bold.woff2') format('woff2');
  unicode-range:
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/SourceSansPro-Latin-ext-Regular-Italic.woff2') format('woff2');
  unicode-range:
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}

/***************/
/* Onest latin */
/***************/
@font-face {
  font-family: 'Onest';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/onest-variable-latin-ext.woff2') format('woff2');
  unicode-range:
    U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0,
    U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Onest';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/onest-variable-latin.woff2') format('woff2');
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/**********/
/* Roboto */
/**********/
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Roboto-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Roboto-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Roboto-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Roboto-Italic.woff2') format('woff2');
}

/********/
/* Lato */
/********/
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/lato-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/lato-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/lato-semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/lato-bold.woff2') format('woff2');
}

/********/
/* Hind */
/********/
@font-face {
  font-family: 'Hind';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Hind-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Hind';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Hind-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Hind';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Hind-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Hind';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Hind-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Hind';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Hind-Bold.woff2') format('woff2');
}

/* Atkinson Hyperlegible */
@font-face {
  font-family: 'Atkinson Hyperlegible';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/AtkinsonHyperlegible-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Atkinson Hyperlegible';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/AtkinsonHyperlegible-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Atkinson Hyperlegible';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/AtkinsonHyperlegible-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Atkinson Hyperlegible';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/AtkinsonHyperlegible-BoldItalic.woff2') format('woff2');
}

/* Poppins */
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Poppins-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Poppins-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Poppins-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/Poppins-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Poppins-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Poppins-BoldItalic.woff2') format('woff2');
}

/* Assistant */
@font-face {
  font-family: 'Assistant';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Assistant-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Assistant';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Assistant-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Assistant';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Assistant-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Assistant';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Assistant-Bold.woff2') format('woff2');
}

/*************/
/* Noto Sans v36 latin */
/*************/
@font-face {
  font-family: 'Noto Sans';
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/noto-sans-v36-latin-200.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/noto-sans-v36-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/noto-sans-v36-latin-500.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/noto-sans-v36-latin-600.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/noto-sans-v36-latin-700.woff2') format('woff2');
}

/**********/
/* Lexend */
/**********/
@font-face {
  font-family: 'Lexend';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Lexend-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Lexend';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Lexend-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Lexend';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Lexend-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Lexend';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Lexend-Bold.woff2') format('woff2');
}

/**********/
/* Source Sans 3 */
/**********/
@font-face {
  font-family: 'Source Sans 3';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/SourceSans3-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans 3';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/SourceSans3-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans 3';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/SourceSans3-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans 3';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/SourceSans3-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans 3';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/SourceSans3-Bold.woff2') format('woff2');
}

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/OpenSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/OpenSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/OpenSans-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/OpenSans-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/OpenSans-Bold.woff2') format('woff2');
}

/* Metropolis */
@font-face {
  font-family: 'Metropolis';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Metropolis-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Metropolis-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Metropolis-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Metropolis-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Metropolis-Bold.woff2') format('woff2');
}
